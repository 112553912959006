import React from "react"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { globalHistory } from "@reach/router";
import { Link } from "gatsby";

const TitleButtonBlock = (props) => {

    const { t } = useTranslation();
    const [lang, setLange] = useState('');
    useEffect(() => {
        setLange(globalHistory.location.pathname.split('/')[1])
    }, [])

    return (
        <section className="default-section section text-center pt-0">
            <div className="container">
                <div className="h1 contact-title">
                    {t(props.item.title)}
                </div>
                <div className="pt-3">
                    <Link to={`/${lang}/${props.item.link}`}
                        className="btn btn-lg btn-dark rounded-pill mt-1">
                        {t(props.item.btn_text)}
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default TitleButtonBlock