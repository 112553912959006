import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ServiceItems from '../components/ServiceItems';
import TitleButtonBlock from '../components/TitleButtonBlock';
import TitleImgBlock from '../components/TitleImgBlock';
import i18n from '../services/translation/i18next';

const ServicesPage = ({
  pageContext: {
    data: { services_content, services_content_header, content_footer },
  },
  ...props
}) => {
  const isLoaded = useSelector((state) => state.timerReducer.isComplete);
  const { t } = useTranslation();
  const data = i18n.t('servicesPage', { returnObjects: true });
  const imageContext = require.context('../images/services', true);
  const img = imageContext('./' + data.title_img);
  const [selectedService, setSelectedService] = useState(null);
  useEffect(() => {
    if (isLoaded) {
      setSelectedService(
        Number.parseInt(
          new URLSearchParams(props.location.search).get('service')
        )
      );
      window.history.pushState('', '', props.location.pathname);
    }
  }, [isLoaded]);
  
  return (
    <Layout>
      <SEO title="Services" />
      <div className="page-services page-default">
        {t('servicesPage', { returnObjects: true }).portfolio && (
          <TitleImgBlock
            image={services_content_header.image.desktop || img}
            title={
              services_content_header.title ||
              t('servicesPage', { returnObjects: true }).servicesPageTitle
            }
          />
        )}

        <div className="container">
          {t('servicesPage', { returnObjects: true }).portfolio && (
            <ServiceItems
              selectedService={selectedService}
              projectsList={
                services_content ||
                t('servicesPage', { returnObjects: true }).portfolio
              }
            />
          )}
        </div>

        {t('servicesPage', { returnObjects: true }).contactUs && (
          <TitleButtonBlock
            item={t('servicesPage', { returnObjects: true }).contactUs}
          />
        )}
      </div>
    </Layout>
  );
};

export default ServicesPage;
