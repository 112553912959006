import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Background, Parallax } from 'react-parallax';
import { createMarkup } from '../../helpers/createMarkup';
import ContactUsAndThanks from '../CombineModals/ContactUsAndThanks';

const ServiceItems = ({ projectsList, selectedService }) => {
  const selectedServiceRef = useRef(null);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [serviceList, setServiceList] = useState(null);

  const imageContext = require.context('../../images/services', true);

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (projectsList.length && !projectsList[0].id) {
      const _projectsList = projectsList.map((service) => ({
        title: service.header,
        button_text: service.btn,
        text: service.full_descr,
        service_image: {
          desktop: imageContext('./' + service.bigImg),
          mobile: imageContext('./' + service.bigImg),
          tablet: imageContext('./' + service.bigImg),
        },
      }));
      setServiceList(_projectsList);
      return;
    }
    setServiceList([...projectsList]);
  }, []);



  useEffect(() => {
    if (selectedServiceRef?.current) {
      selectedServiceRef.current.scrollIntoView({
        block: 'start',
      });
    }
  }, [selectedServiceRef.current]);

  return (
    <div className="services-wrapper">
      {Array.isArray(serviceList) &&
        serviceList.map((service, id) => (
          <div
            className={`service-card`}
            key={id}
            {...(selectedService === id ? { ref: selectedServiceRef } : {})}
          >
            <div
              className="service-card__title"
              dangerouslySetInnerHTML={createMarkup(service.title)}
            />
            <div
              className={`service-card__row ${
                (id + 1) % 2 === 0 ? 'reverse' : ''
              }`}
            >
              <div className="service-card__img">
                <Parallax
                  bgImageAlt=""
                  strength={200}
                  bgImageSize="cover"
                  className="paralax-img "
                  blur={0}
                >
                  <Background className="background_wrapper">
                    <img src={service.service_image.desktop} alt="" />
                  </Background>
                </Parallax>
              </div>

              <div className="service-card__information">
                <div
                  className="service_descr"
                  dangerouslySetInnerHTML={createMarkup(service.text)}
                />
                <div className="service-card__btn">
                  {service.button_text && (
                    <button
                      className="btn btn-transparent"
                      onClick={openModalHandler}
                    >
                      {service.button_text}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      <ContactUsAndThanks show={isOpenModal} onHide={closeModalHandler} />
    </div>
  );
};

export default ServiceItems;
